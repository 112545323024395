import React, {FC, ReactElement, useEffect, useState} from "react";

import { ServiceDetails } from "../../App";
import "./servicesSlider.css"
import { Link } from "react-router-dom";
import Button from "../Button/button";
import ServiceWidget from "../ServicesScroll/serviceWidget/serviceWidget";
import { InView, useInView } from "react-intersection-observer";

interface ServiceSliderProps{
    language: string;
    services: ServiceDetails[];
    selectedPage: string; 
    handleSelectedPage: (value: string) => void
    handleMailSubjectChange: (value: string) => void;
}

const ServicesSlider: FC<ServiceSliderProps> = ({ language, services, selectedPage, handleSelectedPage, handleMailSubjectChange }) => {

    const [liItemSelected, setLiItemSelected] = useState<number>(5);
    const [slideDirection, setSlideDirection] = useState<"left" | "right">("left"); // Estado para a direção do slide
    const [isAnimatingOut, setIsAnimatingOut] = useState<boolean>(false);

    const [ref5, inView5] = useInView({
        triggerOnce: true, // A animação ocorrerá apenas uma vez
    });
    const [ref6, inView6] = useInView({
        triggerOnce: true, // A animação ocorrerá apenas uma vez
    });
    const [ref9, inView9] = useInView({
        triggerOnce: true, // A animação ocorrerá apenas uma vez
    });

    const handleLiItemSelected = (value: number) => {
        if (value > liItemSelected) {
            setIsAnimatingOut(true);
            setSlideDirection("left");
        } else {
            setIsAnimatingOut(true);
            setSlideDirection("right");
        }

        setTimeout(() => {
            setIsAnimatingOut(false);
        }, 200);
        setTimeout(() => { //timeout para nao mudar de serviço enquanto a animaçao esta a ocorrer
            setLiItemSelected(value);
        }, 100)
        
    }

    const renderWidgets = (service: ServiceDetails) => {
        const widgetsArray = new Array(4).fill(null);

        const widgetElements = widgetsArray.map((widget, index) => {
            return (
                < ServiceWidget 
                    key={index}
                    id={index}
                    language={language}
                    service={service}
                />
            );
        });

        return widgetElements;
    }

    return (

        language === "EN" ? (
            <>
                <p className="title">Services</p>
                <p style={{fontSize: "0.9rem", fontFamily: "sans-serif", marginBottom: "0"}}><b>Discover our services</b></p>
                <nav className="nav">
                    <ul>
                        <li className={liItemSelected === 5 ? "selectedItem" : "liItem"}
                            onClick={() => handleLiItemSelected(5)}
                        >
                            Rental
                        </li>
                        <li className={liItemSelected === 6 ? "selectedItem" : "liItem"}
                            onClick={() => handleLiItemSelected(6)}
                        >
                            Insurance
                        </li>
                        <li className={liItemSelected === 9 ? "selectedItem" : "liItem"}
                            onClick={() => handleLiItemSelected(9)}
                        >
                            Guided Trips
                        </li>
                    </ul>
                </nav>
                <div className="sliderContainer">
                    {services
                        .filter((service) => service.id === liItemSelected)
                        .map((service, index) => (
                            <div className={`service-box-with-widgets-wrapper ${isAnimatingOut ? (slideDirection === "left" ? "slideLeft" : "slideRight") : (slideDirection === "left" ? "slideLeftToCenter" : "slideRightToCenter")}`}>
                                {liItemSelected === 5 && (
                                    <>
                                        <div 
                                            key={service.id} 
                                            className={eval(`inView${service.id}`) ? `service-box-with-widgets${service.id}` : "service-box"} 
                                            ref={eval(`ref${service.id}`)}
                                        >
                                        
                                            <h1 className="service-box-titleW">{service.serviceTitleEN}</h1>
                                            <div className="service-box-footerW">
                                                <p>{service.serviceDescriptionEN}</p>
                                                <div className="service-box-footer-btns">
                                                    <button
                                                        onClick={() => handleSelectedPage("Services")}
                                                    >
                                                        <Link to="/services" style={{textDecoration: "none", color: "white"}}>
                                                            Learn More
                                                        </Link>
                                                    </button>
                                                    <Button 
                                                        text={"Buy Service"} 
                                                        selectedPage={"Contact"} 
                                                        handleSelectedPage={handleSelectedPage}
                                                        subject={service.serviceTitleEN}
                                                        handleMailSubjectChange={handleMailSubjectChange}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="widgets-box">
                                            {renderWidgets(service)}
                                        </div>
                                    </>
                                )}

                                {liItemSelected === 6 && (
                                    <>
                                        <div className="widgets-box-vertical">
                                            <ServiceWidget 
                                                id={0}
                                                isFullWidth={true}
                                                language={language}
                                                service={service}
                                            />
                                            <ServiceWidget 
                                                id={1}
                                                isFullWidth={true}
                                                language={language}
                                                service={service}
                                            />
                                        </div>
                                        <div 
                                            key={service.id} 
                                            className={eval(`inView${service.id}`) ? `service-box-with-widgets${service.id}` : "service-box"} 
                                            ref={eval(`ref${service.id}`)}
                                        >
                                        
                                            <h1 className="service-box-titleW">{service.serviceTitleEN}</h1>
                                            <div className="service-box-footerW">
                                                <p>{service.serviceDescriptionEN}</p>
                                                <div className="service-box-footer-btns">
                                                    <button
                                                        onClick={() => handleSelectedPage("Services")}
                                                    >
                                                        <Link to="/services" style={{textDecoration: "none", color: "white"}}>
                                                            Learn More
                                                        </Link>
                                                    </button>
                                                    <Button 
                                                        text={"Buy Service"} 
                                                        selectedPage={"Contact"} 
                                                        handleSelectedPage={handleSelectedPage}
                                                        subject={service.serviceTitleEN}
                                                        handleMailSubjectChange={handleMailSubjectChange}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="widgets-box-vertical">
                                            <ServiceWidget 
                                                id={2}
                                                isFullWidth={true}
                                                language={language}
                                                service={service}
                                            />
                                            <ServiceWidget 
                                                id={3}
                                                isFullWidth={true}
                                                language={language}
                                                service={service}
                                            />
                                        </div>
                                    </>
                                )}
                                
                                {liItemSelected === 9 && (
                                    <>
                                        <div className="widgets-box">
                                            {renderWidgets(service)}
                                        </div>
                                        <div 
                                            key={service.id} 
                                            className={eval(`inView${service.id}`) ? `service-box-with-widgets${service.id}` : "service-box"} 
                                            ref={eval(`ref${service.id}`)}
                                        >
                                            <h1 className="service-box-titleW">{service.serviceTitleEN}</h1>
                                            <div className="service-box-footerW">
                                                <p>{service.serviceDescriptionEN}</p>
                                                <div className="service-box-footer-btns">
                                                    <button
                                                        onClick={() => handleSelectedPage("Services")}
                                                    >
                                                        <Link to="/services" style={{textDecoration: "none", color: "white"}}>
                                                            Learn More
                                                        </Link>
                                                    </button>
                                                    <Button 
                                                        text={"Buy Service"} 
                                                        selectedPage={"Contact"} 
                                                        handleSelectedPage={handleSelectedPage}
                                                        subject={service.serviceTitleEN}
                                                        handleMailSubjectChange={handleMailSubjectChange}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </>
                            )}
                            </div>
                        ))
                    }
                </div>
            </>
        ) : (
            <>
                <p className="title">Serviços</p>
                <p style={{fontSize: "0.9rem", fontFamily: "sans-serif", marginBottom: "0"}}><b>Descubra os nossos serviços</b></p>
                <nav className="nav">
                    <ul>
                        <li className={liItemSelected === 5 ? "selectedItem" : "liItem"}
                            onClick={() => handleLiItemSelected(5)}
                        >
                            Aluguel
                        </li>
                        <li className={liItemSelected === 6 ? "selectedItem" : "liItem"}
                            onClick={() => handleLiItemSelected(6)}
                        >
                            Seguro
                        </li>
                        <li className={liItemSelected === 9 ? "selectedItem" : "liItem"}
                            onClick={() => handleLiItemSelected(9)}
                        >
                            Visitas Guiadas
                        </li>
                    </ul>
                </nav>
                <div className="sliderContainer">
                    {services
                        .filter((service) => service.id === liItemSelected)
                        .map((service, index) => (
                            <div className={`service-box-with-widgets-wrapper ${isAnimatingOut ? (slideDirection === "left" ? "slideLeft" : "slideRight") : (slideDirection === "left" ? "slideLeftToCenter" : "slideRightToCenter")}`}>
                                {liItemSelected === 5 && (
                                    <>
                                        <div 
                                            key={service.id} 
                                            className={eval(`inView${service.id}`) ? `service-box-with-widgets${service.id}` : "service-box"} 
                                            ref={eval(`ref${service.id}`)}
                                        >
                                        
                                            <h1 className="service-box-titleW">{service.serviceTitle}</h1>
                                            <div className="service-box-footerW">
                                                <p>{service.serviceDescription}</p>
                                                <div className="service-box-footer-btns">
                                                    <button
                                                        onClick={() => handleSelectedPage("Services")}
                                                    >
                                                        <Link to="/services" style={{textDecoration: "none", color: "white"}}>
                                                            Saiba Mais
                                                        </Link>
                                                    </button>
                                                    <Button 
                                                        text={"Comprar Serviço"} 
                                                        selectedPage={"Contact"} 
                                                        handleSelectedPage={handleSelectedPage}
                                                        subject={service.serviceTitle}
                                                        handleMailSubjectChange={handleMailSubjectChange}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="widgets-box">
                                            {renderWidgets(service)}
                                        </div>
                                    </>
                                )}

                                {liItemSelected === 6 && (
                                    <>
                                        <div className="widgets-box-vertical">
                                            <ServiceWidget 
                                                id={0}
                                                isFullWidth={true}
                                                language={language}
                                                service={service}
                                            />
                                            <ServiceWidget 
                                                id={1}
                                                isFullWidth={true}
                                                language={language}
                                                service={service}
                                            />
                                        </div>
                                        <div 
                                            key={service.id} 
                                            className={eval(`inView${service.id}`) ? `service-box-with-widgets${service.id}` : "service-box"} 
                                            ref={eval(`ref${service.id}`)}
                                        >
                                        
                                            <h1 className="service-box-titleW">{service.serviceTitle}</h1>
                                            <div className="service-box-footerW">
                                                <p>{service.serviceDescription}</p>
                                                <div className="service-box-footer-btns">
                                                    <button
                                                        onClick={() => handleSelectedPage("Services")}
                                                    >
                                                        <Link to="/services" style={{textDecoration: "none", color: "white"}}>
                                                            Saiba Mais
                                                        </Link>
                                                    </button>
                                                    <Button 
                                                        text={"Comprar Serviço"} 
                                                        selectedPage={"Contact"} 
                                                        handleSelectedPage={handleSelectedPage}
                                                        subject={service.serviceTitle}
                                                        handleMailSubjectChange={handleMailSubjectChange}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="widgets-box-vertical">
                                            <ServiceWidget 
                                                id={2}
                                                isFullWidth={true}
                                                language={language}
                                                service={service}
                                            />
                                            <ServiceWidget 
                                                id={3}
                                                isFullWidth={true}
                                                language={language}
                                                service={service}
                                            />
                                        </div>
                                    </>
                                )}
                                
                                {liItemSelected === 9 && (
                                    <>
                                        <div className="widgets-box">
                                            {renderWidgets(service)}
                                        </div>
                                        <div 
                                            key={service.id} 
                                            className={eval(`inView${service.id}`) ? `service-box-with-widgets${service.id}` : "service-box"} 
                                            ref={eval(`ref${service.id}`)}
                                        >
                                            <h1 className="service-box-titleW">{service.serviceTitle}</h1>
                                            <div className="service-box-footerW">
                                                <p>{service.serviceDescription}</p>
                                                <div className="service-box-footer-btns">
                                                    <button
                                                        onClick={() => handleSelectedPage("Services")}
                                                    >
                                                        <Link to="/services" style={{textDecoration: "none", color: "white"}}>
                                                            Saiba Mais
                                                        </Link>
                                                    </button>
                                                    <Button 
                                                        text={"Comprar Serviço"}
                                                        selectedPage={"Contact"} 
                                                        handleSelectedPage={handleSelectedPage}
                                                        subject={service.serviceTitle}
                                                        handleMailSubjectChange={handleMailSubjectChange}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </>
                            )}
                            </div>
                        ))
                    }
                </div>
            </>
        )
        
    );
}

export default ServicesSlider;