import React, { FC, ReactElement } from "react";

import { useInView } from "react-intersection-observer";

import "./aboutSection.css"

interface AboutSectionProps{
    language: string;
}

const AboutSection: FC<AboutSectionProps> = ({ language }) => {

    const [ref, inView] = useInView({
        triggerOnce: true, // A animação ocorrerá apenas uma vez
    });

    return (

        language === "PT" ? (
            <div className="about-container">
                <div className={inView ? "text-div-about": ""} ref={ref}>
                    <p id="title-about"><span>Sobre a MotorFest</span></p>
                    <div className="text-paragraph">
                        <p>Quando temos critério na compra e venda de veículos usados, experiencia aliada a responsabilidade e bom senso, são sinônimos de bom negócio. Por isso procuramos priorizar bom atendimento, qualidade e valor justo em nossos negócios.</p>
                    </div>
                </div>

                <div className="image-div">
                </div>
                
        </div>
        ) : (
            <div className="about-container">
                <div className={inView ? "text-div-about": ""} ref={ref}>
                    <p id="title-about"><span>About MotorFest</span></p>
                    <div className="text-paragraph">
                        <p>When we have discretion in the purchase and sale of used vehicles, experience combined with responsibility and common sense, they are synonymous with good business. That's why we try to prioritize good service, quality and fair value in our business.</p>
                    </div>
                </div>

                <div className="image-div">
                </div>
                
            </div>
        )
       
    );

}

export default AboutSection;