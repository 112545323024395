import React, {FC, ReactElement, useEffect, useState} from "react";
import { Link } from 'react-router-dom';

import logo from "../../images/MotorFest.png";
import logo_white from "../../images/Motor_Fest_PNG.png"

import Button from "../Button/button";
import LanguageSelector from "../LanguageSelector/languageSelector";

import { FaInstagram, FaFacebookF, FaWhatsapp } from "react-icons/fa"

import "./navbar.css";

interface NavProps{
    language: string;
    handleLanguage: (value: string) => void;
    selectedPage: string;
    handleSelectedPage: (value: string) => void;
    handleMailSubjectChange?: (value: string) => void;
}

const NavBar: FC<NavProps> = ({ language, handleLanguage, selectedPage, handleSelectedPage, handleMailSubjectChange }) => {

    const [ menuToggled, setMenuToggled ] = useState<boolean>(false);
    const [ menuToggledStyle, setMenuToggledStyle ] = useState<string>("");

    const handleMenuToggle = () => {
        if(!menuToggled){
            setMenuToggledStyle("btns-container-toggled");
            setMenuToggled((previousValue) => !previousValue);
        }else{
            setMenuToggledStyle("btns-container");
            setMenuToggled((previousValue) => !previousValue);
        }
    }

    /*useEffect(() => {
        console.log("menuToggled: " + menuToggled);
    }, [menuToggled]);

    useEffect(() => {
        console.log("menuToggledStyle: " + menuToggledStyle);
    }, [menuToggledStyle]);*/

    return (

        language === "EN" ? (
            <div className={selectedPage === "Home" ? "flex-container-home" : "flex-container"}>
                {selectedPage === "Home" && (
                    <div className="background"></div>
                )}
                <div className="header">

                    <img 
                        src={selectedPage === "Home" ? logo_white : logo} 
                        alt="logo" 
                        className={selectedPage === "Home" ? "logo-220" : "logo-120"} 
                        onClick={function() {
                            window.location.href = "/"
                        }}
                    />

                    <button aria-controls="primary-navigation" aria-expanded="false" data-visible="false" className="mobile-nav-toogle" onClick={handleMenuToggle}>
                    </button>

                    <nav>
                        <div className={`${menuToggled ? menuToggledStyle : "hide"}`} id="primary-navigation">
                            <LanguageSelector 
                                language={language} 
                                handleLanguage={handleLanguage}
                                selectedPage={selectedPage}
                                menuToggled={menuToggled}
                            />

                            <Link to="/">
                                <button 
                                    className={selectedPage === "Home" && !menuToggled ? "nav-button nav-button-selected-home" : "nav-button"/*"nav-button selected" : "nav-button"*/} 
                                    onClick={() => handleSelectedPage("Home")}
                                >
                                HOME
                                </button>
                            </Link>

                            <Link to="/services">
                                <button 
                                    className={selectedPage === "Services" ? "nav-button nav-button-selected" : "nav-button"/*"nav-button selected" : "nav-button"*/}  
                                    onClick={() => handleSelectedPage("Services")}
                                >
                                SERVICES
                                </button>
                            </Link>

                            <Link to="/about">
                                <button 
                                    className={selectedPage === "About" ? "nav-button nav-button-selected" : "nav-button"/*"nav-button selected" : "nav-button"*/} 
                                    onClick={() => handleSelectedPage("About")}
                                >
                                ABOUT
                                </button>
                            </Link>

                            <Link to="/contact">
                                <button 
                                    className={selectedPage === "Contact" ? "nav-button nav-button-selected" : "nav-button"/*"nav-button selected" : "nav-button"*/} 
                                    onClick={() => handleSelectedPage("Contact")}
                                >
                                CONTACT
                                </button>
                            </Link>

                            {menuToggledStyle && (
                                <div style={{display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center"}}>
                                    {/*<div className="schedule">
                                        <h3>Schedule</h3>
                                        <p>Weekdays : 8:00am - 10:00pm</p>
                                        <p>Saturday: 8:00am - 2:00pm</p>
                                    </div>*/}
                
                                    <div className="contacts" style={{marginBottom: "30px"}}>
                                        <h3>Contacts</h3>
                                        <p>info@motorfest.pt</p>
                                        <p>+351 966 716 113</p>
                                        <p>+55 24 98134-2833</p>
                                    </div>
                
                                    <div className="social">
                                        <h3>Social</h3>
                                        <div className="icons-container">
                                            <a href="https://www.instagram.com/motorfest.pt/"><FaInstagram size={22} className="icon" /></a>
                                            <a href="https://www.facebook.com/motorfest.pt"><FaFacebookF size={22} className="icon" /></a>
                                            <a href="https://wa.me/+351966716113"><FaWhatsapp size={22} className="icon" /></a>
                                        </div>
                                    </div>
                                </div>
                            )}
                        </div>
                    </nav>    
                </div>

                {selectedPage === "Home" && (
                    <div className="intro-container">
                        <div className="text-div">
                            <h1 className="animateTop">Connecting Continents, Fulfilling Dreams: Travel, Live, and Explore with Us!</h1>
                        </div>
                        <div className="btn-container-contactUs">
                            <Button 
                                text={"Contact Us"} 
                                selectedPage={"Contact"} 
                                handleSelectedPage={handleSelectedPage}
                            />
                        </div>
                    </div>
                )}
            </div>
        ) : (
            <div className={selectedPage === "Home" ? "flex-container-home" : "flex-container"}>
                {selectedPage === "Home" && (
                    <div className="background"></div>
                )}
                <div className="header">

                    <img 
                        src={selectedPage === "Home" ? logo_white : logo} 
                        alt="logo" 
                        className={selectedPage === "Home" ? "logo-220" : "logo-120"} 
                        onClick={function() {
                            window.location.href = "/"
                        }}
                    />

                    <button aria-controls="primary-navigation" aria-expanded="false" data-visible="false" className="mobile-nav-toogle" onClick={handleMenuToggle}>
                    </button>

                    <nav>
                        <div className={`${menuToggled ? menuToggledStyle : "hide"}`} id="primary-navigation">
                            <LanguageSelector 
                                language={language} 
                                handleLanguage={handleLanguage}
                                selectedPage={selectedPage}
                                menuToggled={menuToggled}
                            />

                            <Link to="/">
                                <button 
                                    className={selectedPage === "Home" && !menuToggled ? "nav-button nav-button-selected-home" : "nav-button"/*"nav-button selected" : "nav-button"*/} 
                                    onClick={() => handleSelectedPage("Home")}
                                >
                                INÍCIO
                                </button>
                            </Link>

                            <Link to="/services">
                                <button 
                                    className={selectedPage === "Services" ? "nav-button nav-button-selected" : "nav-button"/*"nav-button selected" : "nav-button"*/}  
                                    onClick={() => handleSelectedPage("Services")}
                                >
                                SERVIÇOS
                                </button>
                            </Link>

                            <Link to="/about">
                                <button 
                                    className={selectedPage === "About" ? "nav-button nav-button-selected" : "nav-button"/*"nav-button selected" : "nav-button"*/} 
                                    onClick={() => handleSelectedPage("About")}
                                >
                                SOBRE
                                </button>
                            </Link>

                            <Link to="/contact">
                                <button 
                                    className={selectedPage === "Contact" ? "nav-button nav-button-selected" : "nav-button"/*"nav-button selected" : "nav-button"*/} 
                                    onClick={() => handleSelectedPage("Contact")}
                                >
                                CONTACTOS
                                </button>
                            </Link>

                            {menuToggledStyle && (
                                <div style={{display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center"}}>
                                    {/*<div className="schedule">
                                        <h3>Schedule</h3>
                                        <p>Weekdays : 8:00am - 10:00pm</p>
                                        <p>Saturday: 8:00am - 2:00pm</p>
                                    </div>*/}
                
                                    <div className="contacts" style={{marginBottom: "30px"}}>
                                        <h3>Contactos</h3>
                                        <p>info@motorfest.pt</p>
                                        <p>+351 966 716 113</p>
                                        <p>+55 24 98134-2833</p>
                                    </div>
                
                                    <div className="social">
                                        <h3>Social</h3>
                                        <div className="icons-container">
                                            <a href="https://www.instagram.com/motorfest.pt/"><FaInstagram size={22} className="icon" /></a>
                                            <a href="https://www.facebook.com/motorfest.pt"><FaFacebookF size={22} className="icon" /></a>
                                            <a href="https://wa.me/+351966716113"><FaWhatsapp size={22} className="icon" /></a>
                                        </div>
                                    </div>
                                </div>
                            )}
                        </div>
                    </nav>    
                </div>

                {selectedPage === "Home" && (
                    <div className="intro-container">
                        <div className="text-div">
                            <h1 className="animateTop">Unindo Continentes, Realizando Sonhos: Viaje, Viva e Explore com a Gente!</h1>
                        </div>
                        <div className="btn-container-contactUs">
                            <Button 
                                text={"Contactar"} 
                                selectedPage={"Contact"} 
                                handleSelectedPage={handleSelectedPage}
                            />
                        </div>
                    </div>
                )}
            </div>
        )
         
    );    
}

export default NavBar;