import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

import Home from './pages/home/home';
import Services from './pages/services/services';
import About from './pages/about/about';
import Contact from './pages/contact/contact';
import ScrollToTop from './components/ScrollToTop/scrollToTop';
import ScrollToBottom from './components/ScrollToBottom/scrollToBottom';

import purchaseSupportImg from "../src/images/services/aquisicao.jpeg";
import documentSupportImg from "../src/images/services/aquisicao.jpeg";
import parkingSupportImg from "../src/images/services/estacionamento.jpeg";
import maintenanceServiceImg from "../src/images/services/maintenance.jpeg";
import rentingServiceImg from "../src/images/services/renting.jpeg";
import insuranceSupportImg from "../src/images/services/insurance.jpeg";
import juridicSupportImg from "../src/images/services/aquisicao.jpeg";
import accountingSupportImg from "../src/images/services/accounting_support.jpg";
import guidedTravelsImg from "../src/images/services/guided_travel.jpeg";
import realEstateImg from "../src/images/services/real_estate.jpg";
import personalizedServiceImg from "../src/images/services/personalized_service.jpeg";

import './App.css';


export interface ServiceDetails{
  id: number;
  serviceTitle: string;
  serviceTitleEN: string;
  serviceDescription: string;
  serviceDescriptionEN: string;
  detailedDescription: string;
  detailedDescriptionEN: string;
  image: string;
  widgets?: number;
}


function App() {

  const [selectedPage, setSelectedPage] = useState<string>("Home");
  const [services, setServices] = useState<ServiceDetails[]>([]);
  const [selectedService, setSelectedService] = useState<number>(1);
  const [language, setLanguage] = useState<string>("EN");

  const [mailSubject, setMailSubject] = useState<string>("");

  const handleMailSubjectChange = (value: string) => setMailSubject(value);

  const handleSelectedPage = (value: string) => {
    setSelectedPage(value);
  }

  const handleLanguage = (value: string) => setLanguage(value);

  const handleSelectedService = (value: number) => {
    setSelectedService((previousValue) => 
      previousValue === 8 && value > 8
      ? 1
      : previousValue === 1 && value < 1
      ? 8
      : value
    );
  }

  const servicesSetup = () => {
    
    const purchaseSupport:ServiceDetails = {
      id: 1,
      serviceTitle: "Ajuda em aquisições",
      serviceTitleEN: "Help with acquisitions",
      serviceDescription: "Apoio na compra do seu carro, motorhome, moto e bicicletas na Europa e América do Sul, com registro de propriedade no nome da pessoa",
      serviceDescriptionEN: "Support when purchasing your car, motorhome, motorcycle and bicycles in Europe and South America, with ownership registration in the person's name",
      detailedDescription: "A nossa equipa de especialistas em aquisição de veículos oferece apoio completo ao longo de todo o processo de compra. Isso inclui pesquisa de mercado, negociação com vendedores, verificação do histórico do veículo e assistência na obtenção de financiamento, se necessário. Garantimos que o veículo seja transferido para o seu nome de forma eficiente e legal, cuidando de todos os aspetos burocráticos.",
      detailedDescriptionEN: "Our team of vehicle acquisition experts offers comprehensive support throughout the entire purchasing process. This includes market research, negotiation with sellers, vehicle history checks, and assistance with financing if needed. We ensure that the vehicle is transferred into your name efficiently and legally, taking care of all the bureaucratic aspects.",
      image: purchaseSupportImg,
    }; 

    const documentSupport:ServiceDetails = {
      id: 2,
      serviceTitle: "Ajuda na retirada de documentos",
      serviceTitleEN: "Help with collecting documents",
      serviceDescription: "Apoio especializado para retirada de documentos para que a pessoa possa comprar e ter os veículos e imóveis no seu nome",
      serviceDescriptionEN: "Specialized support for obtaining documents so that the person can buy and have vehicles and properties in their name",
      detailedDescription: "Simplificamos o processo de aquisição de veículos e propriedades na Europa, fornecendo orientação abrangente sobre os documentos necessários. Isso inclui a obtenção de certidões, autorizações e qualquer outra papelada exigida pelas autoridades locais. Garantimos que todos os documentos estejam em ordem para que possa desfrutar dos seus bens com tranquilidade.",
      detailedDescriptionEN: "We simplify the process of acquiring vehicles and properties in Europe by providing comprehensive guidance on the necessary documents. This includes obtaining certificates, authorizations, and any other paperwork required by local authorities. We ensure that all documents are in order so you can enjoy your assets with peace of mind.",
      image: documentSupportImg,
    };

    const parkingSupport:ServiceDetails = {
      id: 3,
      serviceTitle: "Garagem/Estacionamento",
      serviceTitleEN: "Garage/Parking",
      serviceDescription: "Garagem/Estacionamento do seu veículo, equipamentos e pertences. Evitando assim deslocamento de bagagens e equipamentos",
      serviceDescriptionEN: "Garage/Parking for your vehicle, equipment and belongings. Thus avoiding displacement of luggage and equipment",
      detailedDescription: "Oferecemos um local seguro para estacionar os seus veículos, guardar equipamentos e pertences. A nossa instalação de armazenamento é monitorizada 24 horas por dia, proporcionando total tranquilidade. Não se preocupe mais com a segurança dos seus bens enquanto viaja.",
      detailedDescriptionEN: "We offer a secure location for parking your vehicles and storing equipment and belongings. Our storage facility is monitored 24/7, providing total peace of mind. No more worries about the security of your belongings while you travel.",
      image: parkingSupportImg,
    }; 

    const maintenanceService:ServiceDetails = {
      id: 4,
      serviceTitle: "Serviço de Manutenção",
      serviceTitleEN: "Maintenance Service",
      serviceDescription: "Serviços de manutenção para os seus veículos",
      serviceDescriptionEN: "Maintenance services for your vehicles",
      detailedDescription: "A nossa equipa de mecânicos experientes cuida da manutenção dos seus veículos com atenção aos detalhes. Realizamos verificações regulares, troca de óleo, reparos mecânicos e elétricos, garantindo que os seus veículos estejam sempre em ótimas condições de funcionamento.",
      detailedDescriptionEN: "Our team of experienced mechanics takes care of your vehicle maintenance with attention to detail. We perform regular checks, oil changes, mechanical and electrical repairs, ensuring that your vehicles are always in excellent working condition.",
      image: maintenanceServiceImg,
    }; 

    const rentingService:ServiceDetails = {
      id: 5,
      serviceTitle: "Serviço de Aluguel",
      serviceTitleEN: "Rental Service",
      serviceDescription: "Aluguel de carros, motos, motorhomes e bicicletas",
      serviceDescriptionEN: "Cars, motorbikes, motorhomes and bicycles rental",
      detailedDescription: "Oferecemos uma ampla frota de veículos para aluguer, desde carros económicos até motorhomes espaçosos e bicicletas para explorar a cidade. Escolha entre uma variedade de modelos e desfrute de um aluguer flexível para atender às suas necessidades de viagem.",
      detailedDescriptionEN: "We offer a wide range of vehicles for rent, from economical cars to spacious motorhomes and bicycles for city exploration. Choose from a variety of models and enjoy flexible rentals to meet your travel needs.",
      image: rentingServiceImg,
      widgets: 4,
    }; 

    const insuranceSupport:ServiceDetails = {
      id: 6,
      serviceTitle: "Contratação Seguro Básico",
      serviceTitleEN: "Hiring Basic Insurance",
      serviceDescription: "Contratação de Seguro básico e contra todos os riscos para você, sua familia e seus bens",
      serviceDescriptionEN: "Taking out basic and all-risk insurance for you, your family and your assets",
      detailedDescription: "Proteja os seus bens e a sua tranquilidade com os nossos serviços de seguro. Oferecemos opções de seguro básico que cobrem danos e acidentes comuns, bem como pacotes abrangentes que incluem proteção contra roubos e outros riscos, garantindo total segurança para si, a sua família e os seus bens.",
      detailedDescriptionEN: "Protect your assets and peace of mind with our insurance services. We offer basic insurance options covering common damages and accidents, as well as comprehensive packages that include protection against theft and other risks, ensuring complete security for you, your family, and your assets.",
      image: insuranceSupportImg,
    }; 

    const juridicSupport:ServiceDetails = {
      id: 7,
      serviceTitle: "Apoio Jurídico na Europa e na América do Sul",
      serviceTitleEN: "Legal Support in Europe and South America",
      serviceDescription: "Apoio Jurídico na Europa e na América do Sul",
      serviceDescriptionEN: "Legal Support in Europe and South America",
      detailedDescription: "A nossa equipa de advogados especializados está à disposição para lidar com questões jurídicas civis e criminais. Oferecemos consultoria legal, representação em tribunais e mediação, garantindo que os seus interesses estejam sempre protegidos.",
      detailedDescriptionEN: "Our team of specialized lawyers is available to handle civil and criminal legal matters. We provide legal consultation, representation in courts, and mediation, ensuring that your interests are always protected.",
      image: juridicSupportImg,
    }; 

    const accountingSupport:ServiceDetails = {
      id: 8,
      serviceTitle: "Apoio Contábil",
      serviceTitleEN: "Accounting Support",
      serviceDescription: "Apoio Contábil na Europa e na América do Sul",
      serviceDescriptionEN: "Accounting Support in Europe and South America",
      detailedDescription: "Os nossos serviços contábeis abrangem todas as suas necessidades financeiras, desde a preparação de declarações fiscais até o planeamento financeiro estratégico. Mantenha os seus registos em conformidade com a legislação local e maximize os seus benefícios fiscais.",
      detailedDescriptionEN: "Our accounting services cover all your financial needs, from preparing tax returns to strategic financial planning. Keep your records compliant with local regulations and maximize your tax benefits.",
      image: accountingSupportImg,
    }

    const guidedTravels:ServiceDetails = {
      id: 9,
      serviceTitle: "Viagens Guiadas",
      serviceTitleEN: "Guided Trips",
      serviceDescription: "Viagens pela Europa e pela Améroca do Sul com guia, em carro, moto e/ou bicicleta",
      serviceDescriptionEN: "Travels around Europe and South America with a guide, by car, motorcycle and/or bicycle",
      detailedDescription: "Explore destinos emocionantes na Europa e América do Sul com conforto e segurança nas nossas viagens guiadas. A nossa equipa experiente cuida de todos os detalhes da viagem, desde o planeamento da rota até às acomodações e suporte técnico durante a jornada.",
      detailedDescriptionEN: "Explore exciting destinations in Europe and South America comfortably and safely with our guided tours. Our experienced team takes care of all the travel details, from route planning to accommodations and technical support during the journey.",
      image: guidedTravelsImg,
    }; 

    const realEstate:ServiceDetails = {
      id: 10,
      serviceTitle: "Ajuda no setor Imobiliário",
      serviceTitleEN: "Real Estate Solutions",
      serviceDescription: "Compra, Venda, arrendamento e manutenção de imóveis em Portugal",
      serviceDescriptionEN: "Buying, selling, renting and maintaining properties in Portugal.",
      detailedDescription: "Simplificamos o processo de transações imobiliárias em Portugal. A nossa equipa de agentes imobiliários oferece assistência para compra, venda e arrendamento de propriedades. Além disso, cuidamos da manutenção de imóveis, garantindo que o seu investimento seja bem cuidado.",
      detailedDescriptionEN: "We simplify the real estate transaction process in Portugal. Our team of real estate agents offers assistance for property purchase, sale, and leasing. Additionally, we take care of property maintenance, ensuring that your investment is well cared for.",
      image: realEstateImg,
    }

    const personalizedService:ServiceDetails = {
      id: 11,
      serviceTitle: "Serviço Personalizado",
      serviceTitleEN: "Personalized Service",
      serviceDescription: "Fale connosco e diga-nos que serviço pretende",
      serviceDescriptionEN: "Talk to us and tell us what service you want",
      detailedDescription: "Se tiver necessidades exclusivas que não se encaixam nos serviços mencionados, estamos prontos para criar soluções personalizadas. Entre em contacto connosco para discutir como podemos atender às suas necessidades específicas.",
      detailedDescriptionEN: "If you have unique needs that don't fit into the mentioned services, we are ready to create customized solutions. Contact us to discuss how we can meet your specific requirements.",
      image: personalizedServiceImg,
    }; 

    setServices(() => [purchaseSupport, documentSupport, parkingSupport, maintenanceService, rentingService, insuranceSupport, juridicSupport, accountingSupport, guidedTravels, realEstate,personalizedService]);
  }

  useEffect(() => {
    servicesSetup();
  }, []);

  useEffect(() => {
    console.log(mailSubject);
  }, [mailSubject]);

  return (
    <div className="App">
      <Router>
        {/* Routes */}
        <Routes>
          {/* Home Page */}
          <Route path="/" element={<Home 
                                      language={language} 
                                      handleLanguage={handleLanguage} 
                                      services={services} 
                                      selectedService={selectedService} 
                                      handleSelectedService={handleSelectedService} 
                                      selectedPage={selectedPage} 
                                      handleSelectedPage={handleSelectedPage} 
                                      handleMailSubjectChange={handleMailSubjectChange}
                                      />} 
            />

          {/* Services Page */}
          <Route path="/services" element={<> 
                                              <ScrollToTop /> 
                                              <Services
                                                  language={language} 
                                                  handleLanguage={handleLanguage}  
                                                  services={services} 
                                                  selectedPage={selectedPage} 
                                                  handleSelectedPage={handleSelectedPage} 
                                                  handleMailSubjectChange={handleMailSubjectChange}
                                                  /> 
                                          </>} 
          />

          {/* About Page */}
          <Route path="/about" element={<> 
                                            <ScrollToTop /> 
                                            <About
                                              language={language} 
                                              handleLanguage={handleLanguage}  
                                              selectedPage={selectedPage} 
                                              handleSelectedPage={handleSelectedPage}
                                            /> 
                                        </>} 
          />

          {/* Contact Page */}
          <Route path="/contact" element={<> 
                                            {/*<ScrollToBottom/> */}
                                            <ScrollToTop /> 
                                            <Contact 
                                              language={language} 
                                              handleLanguage={handleLanguage} 
                                              selectedPage={selectedPage} 
                                              handleSelectedPage={handleSelectedPage} 
                                              subject={mailSubject} 
                                              handleMailSubjectChange={handleMailSubjectChange}
                                            /> 
                                          </>} 
          />
        </Routes>

      </Router>
    </div>
  );
}

export default App;
